import * as React from 'react';
import { AppProps, SeoObject } from 'src/app/types';
import { AppPageLayout, ShoppingCartContainer } from 'src/app.consumer/components';
import { Localized } from 'src/app/components';
import { useAppContext } from 'src/app/hooks';

// TODO: maybe merge paypal and other loaded scripts into config object - with seo options?
const seo: SeoObject = {
  title: 'Shopping cart',
};

export const ShoppingCartPage = (props: AppProps) => {
  const { isOrganisationUser } = useAppContext();
  return (
    <AppPageLayout
      subTitle={
        isOrganisationUser ? (
          <Localized
            dictKey={
              'staticLocales:pages./shopping-cart.warning.organisation-account-in-use'
            }
          />
        ) : (
          <Localized dictKey={'staticLocales:pages./shopping-cart.subtitle'} />
        )
      }
      title={<Localized dictKey={'staticLocales:pages./shopping-cart.link label'} />}
      {...{ ...props, seo }}
    >
      <ShoppingCartContainer />
    </AppPageLayout>
  );
};

export default ShoppingCartPage;
